<template>
	<!-- 仓储费明细 - Carton -->
	<div class="app-container"
	:lang="$i18n.locale"
	v-loading.fullscreen="uploadloading" :element-loading-text="$t('tipsInfo.uploading')"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-form style="margin:10px 0 0 0" :model="queryParams" ref="queryParams" :inline="true"
			label-width="auto">
			<el-form-item label prop="packageNo">
				<el-input v-model.trim="queryParams.packageNo" type="textarea" clearable
					:placeholder="$t('commonInfo.packageNo')" style="width: 220px;"></el-input>
			</el-form-item>
			<el-form-item label prop="chargeDateList">
				<el-date-picker v-model="queryParams.chargeDateList" type="daterange" value-format="yyyy-MM-dd"
					range-separator="-" :start-placeholder="$t('commonInfo.beginTime')" :end-placeholder="$t('commonInfo.endTime')" style="margin-right: 10px"
					></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="handleSearch">
					{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button icon="el-icon-refresh" type="primary" @click="getList">
					{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
				</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" ref="listTable" border style="width: 100%;font-size: 16px;"
			v-loading="loading">
			<el-table-column :label="$t('commonInfo.batchNo')" prop="batchNo" min-width="160"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.packageNo')" prop="packageNo" min-width="160"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.count')" prop="quantity" min-width="100"
				align="center"></el-table-column>
			<el-table-column :label="$t('commonInfo.chargeVolumeCbm')" prop="skuVolume" min-width="150"
				align="center"></el-table-column>
			<el-table-column :label="$t('ksaWhInboundLogs.inboundTime')" prop="instockTime" min-width="170"
				align="center"></el-table-column>
			<el-table-column :label="$t('storageCharge.freeStorageDay')" prop="freeChargeDays" min-width="140"
				align="center"></el-table-column>
			<el-table-column :label="$t('storageCharge.startChargeDate')" prop="startChargeDate" min-width="170"
				align="center"></el-table-column>
			<el-table-column :label="$t('storageCharge.chargeDate')" prop="chargeDate" min-width="140"
				align="center"></el-table-column>
			<el-table-column :label="$t('storageCharge.chargeDay')" prop="chargeDays" min-width="140"
				align="center"></el-table-column>
			<el-table-column :label="$t('storageCharge.unitPrice')" prop="price" min-width="140"
				align="center">
				<template slot-scope="{ row }">
					<span>{{row.price}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('storageCharge.totalPrice')" prop="money" fixed="right" width="150"
				align="center">
				<template slot-scope="{ row }">
					<span>{{row.money}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.currency')" prop="currency" fixed="right" width="110"
				align="center">
				<template slot-scope="{ row }">
					<el-tag type="primary">{{row.currency}}</el-tag>
				</template>
			</el-table-column>
		</el-table>
		<div class="pageBottom">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryParams.page" :page-sizes="[10, 30, 50, 200,500]" :page-size="queryParams.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	import {
		formatDate
	} from "@/utils/index";
	export default {
		name:'StorageChargeDetail',
		components: {
		},
		data() {
			return {
				uploadloading:false,
				queryParams: {
					page: 1,
					limit: 10,
					packageNo:'',
					packageNoList:[],
					chargeDateList:[],
					productType:2,// Carton
				},
				tableData: [],
				loading: false,
				total: 0,
			}
		},
		//方法集合
		methods: {
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getList();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getList();
			},
			// 搜索
			handleSearch() {
				if(this.queryParams.packageNo != ''){
					this.queryParams.packageNoList = this.strToArr(this.queryParams.packageNo)
				}else{
					this.queryParams.packageNoList = [];
				}
				this.queryParams.page = 1;
				this.getList();
			},
			// 重置
			handleReset() {
				this.queryParams = {
					page: 1,
					limit: 10,
					packageNo:'',
					packageNoList:[],
					chargeDateList:[],
					productType:2,// Carton
				}
				this.getList();
			},
			// 获取列表
			async getList() {
				this.loading = true;
				this.tableData = [];
				const res = await this.$http.post("/toborder/wh/storageCharge/page", this.queryParams);
				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					this.tableData = data;
					this.loading = false;
				} else {
					this.$message.error(res.msg);
					this.loading = false;
				}
			}
		},
		created() {
			this.getList();
		},
	}
</script>

<style scoped>
	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	
	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	
	.listHeader span {
		display: inline-block;
		float: left;
	}
	.app-container {
		padding: 10px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
	}
</style>